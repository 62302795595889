.centered {
  justify-content: center; /* center items horizontally, in this case */
  align-items: center;     /* center items vertically, in this case */
  display: flex;
}

a{
  color: white;
}

.sun {
  display:flex;
  justify-content: center; /* center items horizontally, in this case */
  align-items: center;     /* center items vertically, in this case */
  border-radius:50%;
  height:200px;
  width:200px;
  right: 100px;
  background:orange;
  box-shadow: 0 0 10px orange,
                0 0 60px orange,
                0 0 200px yellow,
                inset 0 0 80px yellow;
  z-index:12;
  z-index:-99;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
  font-size: 16px;
  display: grid;
  place-items: center;
  min-height: 100vh;
  background-color: #222; 
  
}

.container {
  min-width: 40vh;
  max-width: 70vh;
  margin: 1rem;
  overflow: auto;
  min-height: 300px;
  border: 1px solid #666;
  padding: 1rem;
  border-radius: 7px;
  background-color: #444;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f431;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}

.image{
  height: auto;
  width: auto;
  max-width: 50%;
  min-width: 50%;
}

.moon {
  animation: moonanim 2s 1;
  position: relative;
  margin: 10px;
  width: 200px;
  height: 200px;
  background: #c7cbd0;
  border-radius: 50%;
  box-shadow: inset -25px 0px 0 0px #9098a1;
  transition: transform 0.2s ease-in-out;
}

li {
  position: absolute;
  list-style: none;
  background: #737277;
  border-radius: 50%;
}
li:nth-child(1) {
  left: 25px;
  top: 60px;
  width: 50px;
  height: 50px;
  box-shadow: inset 6px -2px 0 0px #414043;
}
li:nth-child(2) {
  left: 150px;
  top: 50px;
  width: 25px;
  height: 25px;
  box-shadow: inset 3px -1px 0 0px #414043;
}

li:nth-child(3) {
  left: 100px;
  top: 150px;
  width: 25px;
  height: 25px;
  box-shadow: inset 3px -1px 0 0px #414043;
}

li:nth-child(4) {
  left: 50px;
  top: 150px;
  width: 12.5px;
  height: 12.5px;
  box-shadow: inset 2.4px -0.8px 0 0px #414043;
}

li:nth-child(5) {
  left: 87.5px;
  top: 16.66666667px;
  width: 12.5px;
  height: 12.5px;
  box-shadow: inset 2.4px -0.8px 0 0px #414043;
}

li:nth-child(6) {
  left: 114.28571429px;
  top: 80px;
  width: 12.5px;
  height: 12.5px;
  box-shadow: inset 2.4px -0.8px 0 0px #414043;
}

li:nth-child(7) {
  left: 181.81818182px;
  top: 100px;
  width: 12.5px;
  height: 12.5px;
  box-shadow: inset 2.4px -0.8px 0 0px #414043;
}

.p{
  z-index: -1;
  position:fixed;
  left:0px;
  top:50px;
  width:1px;
  height:1px;
  background-color:white;
  position:fixed;
  animation-name:particleAnimation;
  animation-timing-function: infinite;
  animation-iteration-count: infinite;
  -ms-transform: rotate(20deg); /* IE 9 */
  transform: rotate(20deg);
}
.p::before{
  position:absolute;
  display:block;
  content:"";
  width:100px;
  right:1px;
  top:0px;
  height:1px;
  background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(255,255,255,0.4) 100%); 
}
.p-1{
  animation-duration:20s;
  left: 10%;
}
.p-2{
  animation-duration:10s;
  top:60%;
  left: 40%;
}
.p-3{
  animation-duration:40s;
  top:90%;
  left: 60%;
}

.p-4{
  animation-duration:15s;
  left: 25%;
}
.p-5{
  animation-duration:11s;
  top:30%;
  left: 20%;
}
.p-6{
  animation-duration:50s;
  top:33%;
  left: 10%;
}

.p-7{
  animation-duration:5s;
  left: 50%;
}
.p-8{
  animation-duration:3s;
  top:10%;
  left: 30%;
}
.p-9{
  animation-duration:11s;
  top:40%;
  left: 60%;
}

.p-11{
  animation-duration:7s;
  left: 10%;
}
.p-21{
  animation-duration:5s;
  top:60%;
  left: 40%;
}
.p-31{
  animation-duration:11s;
  top:90%;
  left: 60%;
}

.p-41{
  animation-duration:4s;
  left: 25%;
}
.p-51{
  animation-duration:2s;
  top:30%;
  left: 20%;
}
.p-61{
  animation-duration:1s;
  top:33%;
  left: 10%;
}

.p-71{
  animation-duration:4s;
  left: 50%;
}
.p-81{
  animation-duration:1s;
  top:10%;
  left: 30%;
}
.p-91{
  animation-duration:4s;
  top:40%;
  left: 60%;
}

h1,h3,p,label {
  color: whitesmoke;
}

@keyframes particleAnimation
{
    from {
        top: -100px;
    }
  
  50% {
    transform: translateY(500px);
  }
    to {
        left: calc( 100% + 100px );
        top: calc( 100% + 100px );
    }
}

@keyframes tothetop
{
  
  from {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  background: #0c0c2b;
  }
  
  to {
    display: block;
    align-items: none;
    justify-content: none;
    min-height: 0vh;
    align-items: center;
  }
}

@keyframes moonanim
{
  from {
    top: -50vh;
  }
  
  to {
    top: 0;
  }
}

.logo {
  color: white;
  font-family: Sail;
}

img{
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

/* .bplanet {
  background-image: url("imgs/IMG_7847.PNG");
  height: 100px;
  width: 100px;
  
} */

.background-fullviewport{
  right: -50vh;
  width: 100vh;
  padding: 20px;
  height: 0;

}

h1{
  padding: 1vh;
}

@media screen and (max-width: 992px) {

}

#logo{
  max-height: 100px;
}